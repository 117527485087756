import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {DataService} from "../../services/data.service";
import {CacheService} from "../../services/cache.service";
import {NetworkService} from "../../services/network.service";

@Component({
  selector: 'app-reason-modal',
  templateUrl: './reason-modal.component.html',
  styleUrls: ['./reason-modal.component.scss'],
})
export class ReasonModalComponent implements OnInit {

  @Input() reasons: string[];

  selectReasons: any = [];
  selectedReason: string;
  isConnected: boolean;
  visitReasonLabel: string;
  label: string;
  visitReasons: any = [];

  constructor(
    private modalCtrl: ModalController,
    private dataService: DataService,
    private cacheService: CacheService,
    private networkService: NetworkService,
  ) {
  }

  ngOnInit() {
    this.checkConnectivity();
    console.log(this.isConnected);
    this.getReasonList();
    if (this.reasons) {
      this.visitReasons = this.visitReasons.map(reason => {
        if (this.reasons.includes(reason.value)) {
          reason.selected = true;
        }
        return reason;
      });
    }
  }

  async getReasonList() {
    if(this.isConnected) {
      this.visitReasons = await this.dataService.getAllReasons();
    } else {
      this.visitReasons = await this.cacheService.get('reason-list')
    }

    localStorage.setItem('reason-list', this.visitReasons)

    console.log('reasonListModal', this.visitReasons);
  }

  checkConnectivity() {
    this.networkService.status$.subscribe((status) => {
      this.isConnected = status;
    })
  }

  addReasons() {
    const labels = this.visitReasons.filter(label => label.selected).map(label => label.display)
    const reasons = this.visitReasons.filter(reason => reason.selected).map(reason => reason.id);
    console.log('reasons', reasons);
    this.modalCtrl.dismiss({
      reasons,
      labels
    })
  }


  // addReasons() {
  //   const selectedReason = this.selectReasons;
  //   console.log('reasons selecionadas', selectedReason)
  //   const reasonsList = this.visitReasons
  //   let label = this.getReasonDisplay(reasonsList, selectedReason)
  //   // console.log(this.reasons);
  //   this.modalCtrl.dismiss({
  //     selectedReason,
  //     reasonsList,
  //     label
  //   });
  //   // console.log('visitReason', this.visitReason);
  // }

  getReasonDisplay(reasonsList, selectedReason) {
    this.label = reasonsList.filter(reason => {
      if (reason.id === selectedReason) {
        console.log(reason.display)
        this.label = reason.display;
        return reason.display;
      }
    })
    return this.label;
  }

  modalDismiss() {
    this.modalCtrl.dismiss();
  }
}

