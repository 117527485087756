import { Injectable } from '@angular/core';
import { UserModel } from '../domains/models/user.model';

import { Router } from '@angular/router';
import { DataService } from './data.service';
import { ValidatedUser } from '../domains/models/validate-user.model';
import { CacheService } from './cache.service';
import { PushNotifications} from "@capacitor/push-notifications";
import { FCM } from "@capacitor-community/fcm";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  selectedFarm: string;

  constructor(
    private router: Router,
    private dataService: DataService,
    private cacheService: CacheService
  ) {
  }

  async login(userModel: UserModel): Promise<any> {
    try {
      const result: any = await this.dataService.doLogin(userModel);

      if (this.isLaboratory(result.user)) {
        throw Error('Você não está autorizado a acessar o aplicativo.')
      }

      this.setCookie(result);
      return result;
    } catch (error) {
      if(error.status === 400 && error.error.error === "Low App Version"){
        this.router.navigate(['/maintenance'])
      }
      console.error(`Erro ao efetuar login\n`, error);
      throw error;
    }
  }

  public setCookie(user: UserModel) {
    const encoded = btoa(JSON.stringify(user));
    localStorage.setItem('sb-user', encoded);
  }

  isLoggedIn() {
    const { user, token } = this.getUserInfo();
    return (user && token) ? true : false;
  }

  public getUserInfo(): ValidatedUser {
    const userInfo = localStorage.getItem('sb-user');
    const decoded = atob(userInfo);
    const result = userInfo ? JSON.parse(decoded) : undefined;
    return result;
  }

  public getContextAcess() {
    return window.localStorage.getItem('context-acess');
  }

  public isLaboratory(user: UserModel) {
    const isLaboratory = user.roles.some((role) => role.scope === 'laboratorio');
    return isLaboratory;
  }

  public getUserRole() {
    const userInfo = localStorage.getItem('sb-user');
    const decoded = atob(userInfo);
    const result = userInfo ? JSON.parse(decoded) : undefined;
    const userRole = result.user.roles[0].scope
    return userRole;
  }

  public getNotificationToken() {
    const userInfo = localStorage.getItem('sb-user');
    const decoded = atob(userInfo);
    const result = userInfo ? JSON.parse(decoded) : undefined;
    const token = result.notification_token
    return token;
  }


  //criar função de gerenciamento de contexto.

  public isClient(user?: UserModel) {
    if (!user) {
      user = this.getUserInfo().user;
    }
    const isClient = user.roles.some((role) => role.scope === 'cliente');
    return isClient;
  }
  public isSolubio(user?: UserModel) {
    if (!user) {
      user = this.getUserInfo().user;
    }
    const isSolubio = user.roles.some((role) => role.scope === 'solubio');
    return isSolubio;
  }
  public isFranchise(user?: UserModel) {
    if (!user) {
      user = this.getUserInfo().user;
    }
    const isFranchise = user.roles.some((role) => role.scope === 'franchise');
    return isFranchise;
  }

  public logout() {
    localStorage.removeItem('sb-user');
    localStorage.removeItem('reason-list');
    localStorage.removeItem('problem-list');
    localStorage.removeItem('context-acess');
    localStorage.removeItem('remind-later');
    localStorage.removeItem('hasNotificationPermission')
    localStorage.removeItem('already-sync')
    this.unsubscribeFromTopics()
    this.router.navigate(['login']);
  }

  /**
   * se trocar o usuário apaga os dados de login
   *
   */
  public async removeStoredUserOnNewLogin(userModel: UserModel) {
    const { user } = await this.cacheService.get('logged-user');
    if (user && (user.email !== userModel.email)) {
      await this.cacheService.delete('logged-user');
    }
  }

 async unsubscribeFromTopics(){
    let territories = localStorage.getItem('listenedTerritories');
    territories = JSON.parse(territories);
    console.log('territóriosunsub', territories);
    for (const territory of territories) {
      if (territory) {
        try {
          await FCM.unsubscribeFrom({topic: 'territory_' + territory})
          console.log('unsubscribed from territory'+territory);
        } catch (error) {
          console.log('subscribe Error', error)
        }
      }
    }
  }
}
