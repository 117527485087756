export class FarmModel {
  id: number;
  address: string;
  city: string;
  cnpj: string;
  company_name: string;
  company_type: number;
  cpf: string;
  created_at: string;
  deleted_at: null;
  farm_name: string;
  identity_name: string;
  state: string;
  state_register: string;
  inserted_at: string;
  farm_hash?: string;
}


