import { v4 as uuid } from 'uuid';
import { HttpErrorResponse } from "@angular/common/http";

export enum ACTIONS_ENUM {
  ORDEM_SERVICO = 'service-order',
  CHECKLIST = 'checklist-home',
  PRODUCT_REPORT = 'product-report',
  VISIT_DIARY = 'visit-diary',
  COMPATIBILITY_SIMULATOR = 'compatibility-simulator-selection',
  MANAGEMENT_PLAN = 'management-plan',
  TIMELINE = 'timeline',
  STOCK_CONTROL = 'stock-control'
}

export function translateStatus(status: string) {
  if (status === 'URGENT') { return 'Urgente'; }
  if (status === 'IMPORTANT') { return 'Importante'; }
  if (status === 'SCHEDULED') { return 'Programado'; }
  if (status === 'CANCELED') { return 'Cancelado'; }
  return status;
}

export const errorHandler = (error: any): any => {
  if (error instanceof (HttpErrorResponse)) {
    error = error.error.error;
  }

  return {
    'Wrong Username or Password': 'Usuário ou senha incorretos!',
    'Internal Server Error': 'Erro Interno no Servidor'
  }[error] || error;
}

export const uuidv4 = () => {
  return uuid();
}

// somente para exibir dados offline
export const generateTempSoNumber = (index: number) => {
  return new Date().getFullYear().toString().substr(-2) + '-' + ("0000" + (index + 1)).slice(-4)
}

// somente para exibir dados offline
export const generateTempCheckListNumber = (index: number) => {
  const year = new Date().getFullYear().toString().substr(-2);
  const tempNumber = ('0000' + (index + 2)).slice(-4);
  const result = `${year}-${tempNumber}`;
  return result;
}

// valida para evitar objetos no cache
export const itemExists = (item: any, collection: any) => {
  const exists = collection.some((col: any) => col.id == item.id);
  return exists;
}

/*
Método de ordenação
por uma propriedade qualquer
*/
export const sortByProperty = (property: string) => {
  return function (a, b) {
    if (a[property] < b[property]) {
      return 1;
    } else if (a[property] > b[property]) {
      return -1;
    }
    return 0;
  }
}
