import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(
        private router: Router, private authService: AuthService) { }

    handleError(error: any): void {
        const invalidTokenMessage = new String(error).includes("Token is not valid");
        if (invalidTokenMessage) {
            this.authService.logout();
        }
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
        }
    }
}