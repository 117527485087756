import {Component, Input, NgZone, OnInit, ViewEncapsulation} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {DataService} from "../../services/data.service";

@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.component.html',
  styleUrls: ['./product-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class ProductModalComponent implements OnInit {


  @Input() titleModal: string;
  @Input() selected: any;
  @Input() productsList: any;
  @Input() managementPlan: any;
  @Input() goalPlan: any;
  @Input() mode: string;


  product: any;
  amount: string;
  editMode: boolean;
  editedProduct: any[]
  compareWith: any;
  popOverOptions: any = {
    size: 'cover'
  }


  constructor(
    private modalCtrl: ModalController,
    private ngZone: NgZone,
  ) {
  }

  async ngOnInit() {
    this.isEditMode(this.mode)
    console.log('product', this.product);
    console.log('selected', this.selected);
    console.log('productList', this.productsList)
    this.compareWith = (o1, o2) =>{
      return o1 && o2 ? o1 == o2 : false;
    }

  }

  isEditMode(mode) {
    if (mode === 'Editar') {
      this.editMode = true;
      console.log('entrou no editmode');
      if (this.selected) {
        this.ngZone.run(zone => {
          this.product = this.selected.product_id;
          this.amount = this.selected.amount;
          this.editedProduct = this.selected.id
        })
      } else {
        this.product = '';
      }
    }
    console.log('editModeProduct', this.product);
  }

  addProduct(product, amount, action) {
    this.modalCtrl.dismiss({
      product,
      amount,
      action,
    });
  }

  deleteProduct(productId, action) {
    this.modalCtrl.dismiss({
      productId,
      action
    });
  }

  editProduct(product, amount, editedId, action) {
    this.modalCtrl.dismiss({
      product,
      amount,
      editedId,
      action
    })

  }

  modalDismiss() {
    this.modalCtrl.dismiss();
  }

  showProduct() {
    console.log('product', this.product);
    console.log('ammount', this.amount);
  }

}
