import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Awaited } from '../utils/types';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor(private storage: Storage) { }

  async getAll<T = any>(key: string): Promise<Awaited<T>> {
    const all: Awaited<T> = await this.get<T>(key)
    return all;
  }

  async get<T = any>(key: any): Promise<Awaited<T>> {
    const data: Awaited<T> = await this.storage.get(key);
    return data ? data : Promise.resolve(([] || {}) as Awaited<T>);
  }

  async insert<T>(key: string, values: T): Promise<Awaited<T>> {
    await this.storage.set(key, values);
    const insertedResults: Awaited<T> = await this.storage.get(key);
    return insertedResults as Awaited<T>;
  }

  async delete(key: any): Promise<void> {
    await this.storage.remove(key);
  }

  async update<T>(key: any, value: T): Promise<Awaited<T>> {
    await this.storage.set(key, value);
    const updatedResults: Awaited<T> = await this.storage.get(key);
    return updatedResults;
  }


}

export enum CACHE_OBJECTS {
  SERVICE_ORDERS_COMMENTS = 'service-orders-comments'
}
