import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: 'home',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'profile',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'profile-edit',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./pages/profile-edit/profile-edit.module').then(
        (m) => m.ProfileEditPageModule
      ),
  },
  {
    path: 'checklist-home',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./pages/checklist/checklist-home/checklist-home.module').then(
        (m) => m.ChecklistHomePageModule
      ),
  },
  {
    path: 'checklist-type',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./pages/checklist/checklist-type/checklist-type.module').then(
        (m) => m.ChecklistTypePageModule
      ),
  },
  {
    path: 'checklist-list',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./pages/checklist/checklist-list/checklist-list.module').then(
        (m) => m.ChecklistListPageModule
      ),
  },
  {
    path: 'service-order',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./pages/service-order/service-order.module').then(
        (m) => m.ServiceOrderPageModule
      ),
  },
  {
    path: 'resolution-os',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./pages/resolution-os/resolution-os.module').then(
        (m) => m.ResolutionOsModule
      ),
  },
  {
    path: 'product-report',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./pages/product-report/product-report.module').then(
        (m) => m.ProductReportPageModule
      ),
  },
  {
    path: 'service-order-edit/:id',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./pages/service-order-edit/service-order-edit.module').then(
        (m) => m.ServiceOrderEditPageModule
      ),
  },
  {
    path: 'service-order-edit',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./pages/service-order-edit/service-order-edit.module').then(
        (m) => m.ServiceOrderEditPageModule
      ),
  },
  {
    path: 'service-order-detail/:id',
    loadChildren: () =>
      import('./pages/service-order-detail/service-order-detail.module').then(
        (m) => m.ServiceOrderDetailPageModule
      ),
  },
  {
    path: 'checklist-results/:id',
    loadChildren: () =>
      import(
        './pages/checklist/checklist-results/checklist-results.module'
      ).then((m) => m.ChecklistResultsPageModule),
  },
  {
    path: 'product-report',
    loadChildren: () =>
      import('./pages/product-report/product-report.module').then(
        (m) => m.ProductReportPageModule
      ),
  },
  {
    path: 'product-report-detail/:id',
    loadChildren: () =>
      import('./pages/product-report-detail/product-report-detail.module').then(
        (m) => m.ProductReportDetailPageModule
      ),
  },
  {
    path: 'maintenance',
    loadChildren: () =>
      import('./pages/maintenance/maintenance.module').then(
        (m) => m.MaintenancePageModule
      ),
  },
  {
    path: 'visit-diary',
    loadChildren: () =>
      import('./pages/visit-diary/visit-diary.module').then(
        (m) => m.VisitDiaryPageModule
      ),
  },
  {
    path: 'visit-diary-edit',
    loadChildren: () =>
      import('./pages/visit-diary-edit/visit-diary-edit.module').then(
        (m) => m.VisitDiaryEditPageModule
      ),
  },
  {
    path: 'visit-diary-detail',
    loadChildren: () =>
      import('./pages/visit-diary-detail/visit-diary-detail.module').then(
        (m) => m.VisitDiaryDetailPageModule
      ),
  },
  {
    path: 'visit-diary-detail/:id',
    loadChildren: () =>
      import('./pages/visit-diary-detail/visit-diary-detail.module').then(
        (m) => m.VisitDiaryDetailPageModule
      ),
  },
  {
    path: 'compatibility-simulator',
    loadChildren: () =>
      import(
        './pages/compatibility-simulator/compatibility-simulator.module'
      ).then((m) => m.CompatibilitySimulatorPageModule),
  },
  {
    path: 'management-plan',
    loadChildren: () =>
      import('./pages/management-plan/management-plan.module').then(
        (m) => m.ManagementPlanPageModule
      ),
  },
  {
    path: 'management-plan-new',
    loadChildren: () =>
      import('./pages/management-plan-new/management-plan-new.module').then(
        (m) => m.ManagementPlanNewPageModule
      ),
  },
  {
    path: 'management-plan-list',
    loadChildren: () =>
      import('./pages/management-plan-list/management-plan-list.module').then(
        (m) => m.ManagementPlanListPageModule
      ),
  },
  {
    path: 'management-plan-view/:id',
    loadChildren: () =>
      import('./pages/management-plan-view/management-plan-view.module').then(
        (m) => m.ManagementPlanViewPageModule
      ),
  },
  {
    path: 'compatibility-simulator-selection',
    loadChildren: () =>
      import(
        './pages/compatibility-simulator-selection/compatibility-simulator-selection.module'
      ).then((m) => m.CompatibilitySimulatorSelectionPageModule),
  },
  {
    path: 'timeline',
    loadChildren: () =>
      import('./pages/webview-timeline/webview-timeline.module').then(
        (m) => m.WebviewTimelineModule
      ),
  },
  {
    path: 'stock-control',
    loadChildren: () =>
      import('./pages/stock-control/stock.module').then((m) => m.StockModule),
  },
];

@NgModule({
  imports: [
    FormsModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule, FormsModule],
})
export class AppRoutingModule {}
