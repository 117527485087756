import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {PluginListenerHandle} from '@capacitor/core';
import {Network} from '@capacitor/network';
import {BehaviorSubject} from 'rxjs';
import {UtilsService} from "./utils.service";

@Injectable({
  providedIn: 'root'
})
export class NetworkService implements OnDestroy {
  private networkStatus: any;
  status$;
  _isConnected: boolean;
  networkListener: PluginListenerHandle;
  message: string;

  constructor(
    private utilsService: UtilsService,
  ) {
    this.startNetworkListener();
  }

  async startNetworkListener() {
    const {connected} = await this.getNetWorkStatus()
    this._isConnected = connected;
    this.status$ = new BehaviorSubject<boolean>(connected);
    this.status$.asObservable();

    this.networkListener = Network.addListener('networkStatusChange', async (status) => {
      this._isConnected = status.connected;

      if(!this._isConnected){
        this.utilsService.presentDefaultToast(
          'Apresentando funcionalidades limitadas em razão da falta de conexão com a internet.',
          'danger',
          'top',
          3000)
        this.message = 'Você está offline!';
      } else {
        this.message = '';
      }

      this.status$.next(status.connected);
    });

    // this.status$.subscribe((res: boolean) => {
    //   if (!res) this.message = 'Você está offline!';
    // });
  }

  ngOnDestroy() {
    this.endNetworkListener();
  }

  async isConnected() {
    const status = await Network.getStatus();
    return status.connected;
  }
  
  async hasChanged() {
    let handler = await Network.addListener('networkStatusChange', (status) => status);
    return handler;
  }
  
  async getNetWorkStatus() {
    this.networkStatus = await Network.getStatus();
    return this.networkStatus;
  }


  endNetworkListener() {
    if (this.networkListener) {
      this.networkListener.remove();
    }
  }


}
