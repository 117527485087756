import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {NetworkService} from "../../services/network.service";
import {Data} from "@angular/router";
import {DataService} from "../../services/data.service";
import {CacheService} from "../../services/cache.service";

@Component({
  selector: 'app-problems-modal',
  templateUrl: './problems-modal.component.html',
  styleUrls: ['./problems-modal.component.scss'],
})
export class ProblemsModalComponent implements OnInit {

  @Input() problems: string[];

  isConnected: boolean;
  osProblems: any = [];

  constructor(
    private modalCtrl: ModalController,
    private networkService: NetworkService,
    private dataService: DataService,
    private cacheService: CacheService
  ) {}

  ngOnInit() {
    this.checkConnectivity();
    this.getProblemsList()
    if (this.problems)
      this.osProblems = this.osProblems.map(problem => {
          if (this.problems.includes(problem.value))
            problem.selected = true;
          return problem;
        }
      )
  }

  checkConnectivity() {
    this.networkService.status$.subscribe((status) => {
      this.isConnected = status;
    })
  }

  async getProblemsList() {
    if(this.isConnected) {
      this.osProblems = await this.dataService.getAllProblems();
    } else {
      this.osProblems = await this.cacheService.get('problem-list')
    }

    console.log('problemListModal', this.osProblems);
  }

  addProblems() {
    const labels = this.osProblems.filter(label => label.selected).map(label => label.display)
    const problems = this.osProblems.filter(problem => problem.selected).map(problem => problem.id);
    this.modalCtrl.dismiss({
      problems,
      labels
    })
  }

  modalDismiss() {
    this.modalCtrl.dismiss()
  }
}
