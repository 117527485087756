import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { PopoverComponent } from './components/popover/popover.component';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { AlertComponentModule } from './components/alert/alert.component.module';
import { GlobalErrorHandler } from './utils/global-error-handler';
import { SearchFarmsPipe } from './components/pipes/search-farms.pipe';
import { AppVersion } from "@ionic-native/app-version/ngx";
import { Market } from "@ionic-native/market/ngx";
import { LocalDateTimePipe } from "./components/pipes/local-date-time.pipe";
import { ProblemsModalComponent } from "./components/problems-modal/problems-modal.component";
import { ReasonModalComponent } from './components/reason-modal/reason-modal.component';
import { ModalCompatibilityComponent } from './components/modal-compatibility/modal-compatibility.component';
import { NativeGeocoder } from "@awesome-cordova-plugins/native-geocoder/ngx";
import { CultureModalComponent } from "./components/culture-modal/culture-modal.component";
import { ProductModalComponent } from "./components/product-modal/product-modal.component";
import { SwiperModule } from "swiper/angular";
import { FilterTimelineModalComponent } from './components/filter-timeline-modal/filter-timeline-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    PopoverComponent,
    ProblemsModalComponent,
    ReasonModalComponent,
    CultureModalComponent,
    ProductModalComponent,
    ModalCompatibilityComponent,
    SearchFarmsPipe,
    FilterTimelineModalComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    AlertComponentModule,
    IonicStorageModule.forRoot({
      name: '__sbdb',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    }),
    SwiperModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: AppVersion },
    { provide: Market },
    { provide: LocalDateTimePipe },
    NativeGeocoder,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
