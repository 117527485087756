import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { NetworkService } from './services/network.service';
import { AppVersion } from "@ionic-native/app-version/ngx";
import { UtilsService } from "./services/utils.service";


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  message: string;

  constructor(
    private storage: Storage,
    public networkService: NetworkService,
    private appVersion: AppVersion,
    private utilsService: UtilsService,

  ) {
    this.appVersion.getVersionNumber().then(appVersion => {
      console.log(appVersion);
      window.localStorage.setItem('appVersion', appVersion);
    });
  }

  ngAfterViewChecked() {
    // this.checkConnectivity();
  }

  async ngOnInit() {
    await this.storage.create();
    await this.checkConnectivity();
    // if (!this.networkService._isConnected){
    //   console.log('conectionStatus', this.networkService._isConnected);
    //   this.utilsService.presentDefaultToast(
    //     'Apresentando funcionalidades limitadas em razão da falta de conexão com a internet.',
    //     'danger',
    //     'top',
    //     3000)
    // }

  }

  // checkConnectivity() {
  //   this.networkService.status$.subscribe((res: boolean) => {
  //     if (!res) {
  //       // this.utilsService.presentDefaultToast(
  //       //   'Apresentando funcionalidades limitadas em razão da falta de conexão com a internet.',
  //       //   'danger',
  //       //   'top',
  //       //   3000)
  //       this.networkService.message = 'Você está offline!';
  //       setTimeout(() => {
  //         this.networkService.message = '';
  //       }, 3000);
  //     }
  //   });
  // }

  async checkConnectivity() {
    await this.networkService.startNetworkListener();
    if (this.networkService._isConnected) {
      return
    } else {
      console.log('conectionStatus', this.networkService._isConnected)

      this.utilsService.presentDefaultToast(
        'Apresentando funcionalidades limitadas em razão da falta de conexão com a internet.',
        'danger',
        'top',
        3000)
    }
  }

}
