import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {DataService} from "../../services/data.service";
import {CacheService} from "../../services/cache.service";


@Component({
  selector: 'app-culture-modal',
  templateUrl: './culture-modal.component.html',
  styleUrls: ['./culture-modal.component.scss'],
})
export class CultureModalComponent implements OnInit {

  @Input() titleModal: string;
  @Input() selected: string;
  @Input() cultures: any;


  culture: string;


  constructor(
    private modalCtrl: ModalController,
    private dataService: DataService,
  ) {
  }

  async ngOnInit() {
    if (this.selected) {
      this.culture = this.selected;
    } else {
      this.culture = '';
    }

  }

  addCulture(culture) {
    this.modalCtrl.dismiss({
      culture,
    });
  }

  modalDismiss() {
    this.modalCtrl.dismiss();
  }

}
