import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-modal-compatibility',
  templateUrl: './modal-compatibility.component.html',
  styleUrls: ['./modal-compatibility.component.scss'],
})
export class ModalCompatibilityComponent implements OnInit {

  @Input() items: string[];
  @Input() selected: string;
  @Input() titleModal: string;

  item: string;

  constructor(
    private modalCtrl: ModalController,
  ) {
  }

  ngOnInit() {
    if (this.selected) {
      this.item = this.selected;
    } else {
      this.item = '';
    }
  }

  addReasons(item) {
    this.modalCtrl.dismiss({
      item,
    });
  }

  modalDismiss() {
    this.modalCtrl.dismiss();
  }
}
