import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Platform, ToastController} from '@ionic/angular';
import {Observable, throwError} from 'rxjs';
import {catchError, timeout} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import {AppVersion} from '@ionic-native/app-version/ngx';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  private _appVersion: string;
  private _platformIdentifier: string;

  constructor(
    private appVersion: AppVersion,
    private authService: AuthService,
    private toastController: ToastController,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private platform: Platform
  ) {
    this._appVersion = window.localStorage.getItem('appVersion');
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const userInfo = this.authService.getUserInfo();
    const isLoggedIn = userInfo?.user && userInfo?.token;
    const isAuthApiUrl = request.url.startsWith(environment.authApi)
      || request.url.startsWith(environment.api);


    if (!isLoggedIn && isAuthApiUrl) {
      request = request.clone({
        setHeaders: {
          AppVersion: this.platform.is('capacitor') ? this._appVersion : '1.6.5',
          RequestFrom: 'App'
        }
      })
    } else if (isLoggedIn && isAuthApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${userInfo.token}`,
          AppVersion: this.platform.is('capacitor') ? this._appVersion : '1.6.5',
          platformIdentifier: this.platform.is('ios') ? 'ios' : 'android',
          RequestFrom: 'App'
        }
      });
    }
    // this.platform.is('capacitor') ? this._appVersion :

    return next.handle(request).pipe(catchError(error => this.handleError(error)));

  }

  private handleError(error: HttpErrorResponse) {

    if (error.error instanceof ErrorEvent) {
      // Erro de client-side ou de rede
      console.error('Ocorreu um erro:', error.error.message);
    } else {
      // Erro retornando pelo backend
      console.error(
        `Código do erro ${error.status}, ` +
        `Erro: ${JSON.stringify(error.error)}`
      );
    }

    if (error.status === 400 && error.error.message === 'Low App Version') {
      this.updateAppRouter(['/maintenance', {title: error.error.message}])
    } else if (error.status === 403) {
      setTimeout(() => {
        this.presentToast('Sua sessão expirou!');
        this.authService.logout();
      }, 3000);
    }
    // retornar um observable com uma mensagem amigavel.
    return throwError(error);
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message,
      cssClass: 'my-custom-class',
      duration: 2000,
      position: 'top'
    });
    toast.present();
  }

  updateAppRouter(page) {
    this.router.navigate(page);
  }

}
