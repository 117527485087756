export enum EnvironmentName {
  development = 'development',
  homolog = 'homolog',
  production = 'production',
}

export interface Environment {
  name: EnvironmentName;
  production: boolean;
  authApi: string;
  api: string;
}

export const environmentNames: string[] = Object.keys(EnvironmentName).map(
  (key) => EnvironmentName[key]
);
