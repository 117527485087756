import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ChecklistModel } from '../domains/models/checklist.model';
import { FarmModel } from '../domains/models/farm.model';
import { QuestionModel } from '../domains/models/question.model';
import { SectionModel } from '../domains/models/section.model';
import { ServiceOrderModel } from '../domains/models/service-order.model';
import { UserModel } from '../domains/models/user.model';
import { ValidatedUser } from '../domains/models/validate-user.model';
import {
  generateTempCheckListNumber,
  generateTempSoNumber,
  itemExists,
  sortByProperty,
} from '../utils';
import { CACHE_OBJECTS, CacheService } from './cache.service';
import { NetworkService } from './network.service';
import { CommentModel } from '../domains/models/comment.model';
import { VisitDiaryModel } from '../domains/models/visit-diary.model';
import { PlanModel } from '../domains/models/plan.model';
import { ProductReportModel } from '../domains/models/product-report.model';
import { LaboratoryModel } from '../domains/models/laboratory.mode';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  imageBase64: any[];

  constructor(
    private http: HttpClient,
    private cacheService: CacheService,
    private networkService: NetworkService
  ) {}

  /**
   * DO LOGIN
   * Condition: Online
   */
  async doLogin(userModel: UserModel): Promise<ValidatedUser> {
    try {
      const credentials = {
        uid: userModel.email,
        password: userModel.password,
      };

      const validatedUser = await this.http
        .post<ValidatedUser>(`${environment.authApi}/auth/login`, credentials)
        .pipe(map((result) => result))
        .toPromise();

      await this.cacheService.insert('logged-user', validatedUser);

      return validatedUser;
    } catch (error) {
      console.error('Erro ao efetuar login', error);
      throw error;
    }
  }

  /**
   * Faz download dos itens
   * necessário quando online
   */
  async syncContentWhenOnline(userId: number) {
    await this.getUserFarmsOnline();
    await this.createCompatibilityCache();
    await this.createCacheReasons();
    await this.createCacheProblems();
    await this.getProductList();
    await this.getAllSectionsOnline();
    await this.getAllQuestionsOnline();
    await this.getUserProfileOnline(userId);
    await this.syncServiceOrders();
    await this.syncChecklists();
    await this.syncVisitDiaries();
    await this.syncAllPlans();

    // await this.sendAllServiceOrdersWhenOnline();
    // await this.sendAllChecklistsWhenOnline();
  }

  /**
   * GET USER PROFILE
   *
   * Condition: Online
   */
  async getUserProfileOnline(userId: number): Promise<UserModel> {
    console.log('pegou o userProfile');
    try {
      const profile = await this.http
        .get<UserModel>(`${environment.authApi}/api/v1/user/${userId}`)
        .pipe(map((profile) => profile))
        .toPromise();

      const userProfile = await this.cacheService.insert(
        'user-profile',
        profile
      );
      return userProfile;
    } catch (error) {
      console.error('Erro ao recuperar perfil do usuário do servidor', error);
    }
  }

  async getUserProfile(): Promise<UserModel> {
    try {
      const profile = await this.cacheService.get('user-profile');
      return profile;
    } catch (error) {
      console.error('Erro ao recuperar perfil do usuário', error);
    }
  }

  /**
   * UPDATE USER PROFILE
   *
   * Condition: Online
   */
  updateUserProfileWhenOnline(user: UserModel): Observable<any> {
    try {
      const userId = user.id;
      delete user.id;
      const updatedUserProfile = this.http.put<any>(
        `${environment.authApi}/api/v1/user/${userId}`,
        user
      );
      return updatedUserProfile;
    } catch (error) {
      console.error('Erro ao atualizar o perfil do usuário online.', error);
    }
  }

  /**
   * UPDATE USER PROFILE
   *
   * Condition: Offline
   */
  async updateUserProfile(user: UserModel): Promise<any> {
    try {
      const updatedUserProfile = await this.cacheService.update(
        'user-profile',
        user
      );
      return updatedUserProfile;
    } catch (error) {
      console.error(
        'Erro ao atualizar perfil do usuário no dispositivo.',
        error
      );
    }
  }

  /**
   * UPDATE USER PHOTO
   *
   * Condition: Online
   *
   * Experimental: Verificar quando isso será útil
   * uma vez que o usuário sincroniza a foto no login
   *
   * Observação: Nào está sendo usado até o momento
   *
   * 01/10/2021
   */
  async updateUserPhotoOnline(id: number): Promise<UserModel> {
    try {
      const foundUserProfile = await this.cacheService.get('user-profile');
      const photo = foundUserProfile.photo;
      const userPhoto = await this.http
        .post<UserModel>(`${environment.authApi}/api/v1/user/${id}/photo`, {
          photo,
        })
        .toPromise();
      return userPhoto;
    } catch (error) {
      console.error('Erro ao atualizar a foto do usuário', error);
    }
  }

  /**
   * Condition: Offline
   *
   * Sincroniza quando user estiver online
   */
  async updateUserPhoto(photo: string): Promise<UserModel> {
    try {
      const foundUserProfile = await this.cacheService.get('user-profile');
      foundUserProfile.photo = photo;

      const updatedUserProfile = await this.cacheService.update(
        'user-profile',
        foundUserProfile
      );
      return updatedUserProfile.photo;
    } catch (error) {
      console.error('Erro ao atualizar a foto do usuário', error);
    }
  }

  /**
   * Condition: Online
   */
  updateUserPassword(user: any): Observable<any> {
    return this.http.post<any>(`${environment.authApi}/auth/changepass`, user);
  }

  /**
   * GET FARMS
   * Condition: Online
   *
   * Must be on line at least
   * at once to get farms by logged user
   */
  async getUserFarmsOnline(): Promise<FarmModel[]> {
    console.log('pegou as farms online');
    try {
      const params = `?orderby=farm_name&order=asc`;
      let userFarms = await this.http
        .get<FarmModel[]>(`${environment.api}/api/v1/farm/${params}`)
        .pipe(map((farms) => farms))
        .toPromise();

      console.log('inseriu as farms no cache');
      return await this.cacheService.insert('get-user-farms', userFarms);
    } catch (error) {
      console.error('Erro ao buscar lista de farms do backend', error);
    }
  }

  /**
   *
   * Condition: Offline
   */
  async getUserFarms(): Promise<FarmModel[]> {
    try {
      const userFarms = await this.cacheService.get('get-user-farms');
      return userFarms;
    } catch (error) {
      console.error('Erro ao buscar lista de farms do backend', error);
    }
  }

  /**
   * GET FARMS By USERID
   *
   * Condition: Offline
   */
  async getFarmById(id: number): Promise<FarmModel> {
    try {
      const farms = await this.cacheService.get('get-user-farms');
      const farmId = farms.filter((farm: FarmModel) => farm.id == id);

      return farmId[0];
    } catch (error) {
      console.error('Erro ao buscar fazenda por id', error);
    }
  }

  /**
   * VISIT DIARIES
   *
   * Condition: Online & Offline
   */

  async createVisitDiaryWhenOnline(visitDiary: any): Promise<VisitDiaryModel> {
    try {
      const response = await this.http
        .post<VisitDiaryModel[]>(`${environment.api}/api/v1/diary`, visitDiary)
        .pipe(map((visitDiary) => visitDiary))
        .toPromise();

      return response[0];
    } catch (error) {
      console.error('Erro ao criar o diário de visitas', error);
    }
  }

  /**
   * Condition: Offline
   */
  async createVisitDiaryWhenOffline(visitDiary: any): Promise<VisitDiaryModel> {
    try {
      let response = await this.cacheService.get('visit-diaries');
      response.push(visitDiary);
      console.log('array de visitas', response);

      let responseValue = await this.cacheService.insert(
        'visit-diaries',
        response
      );
      console.log('insert visits', responseValue);
      return responseValue;
    } catch (error) {
      console.error('Erro ao criar ordem de serviço', error);
    }
  }

  async getAllReasons() {
    try {
      const reasonList = await this.http
        .get(`${environment.api}/api/v1/reason_list`)
        .pipe(map((list) => list))
        .toPromise();
      return reasonList;
    } catch (error) {
      console.error('Erro ao carregar a lista de motivos');
    }
  }

  async getAllProblems() {
    try {
      const problemsList = await this.http
        .get(`${environment.api}/api/v1/problem_list`)
        .pipe(map((list) => list))
        .toPromise();
      return problemsList;
    } catch (error) {
      console.error('Erro ao carregar a lista de problemas');
    }
  }

  async getResolutionSubId(id) {
    try {
      const resolution = await this.http
        .get<any[]>(`${environment.api}/api/v1/resolution_os/sub/${id}`)
        .toPromise();
      console.log('resolution Id sub', resolution);
      return resolution;
    } catch (error) {
      console.log('Erro ao receber a resolution', error);
    }
  }

  async getResolutionId(id) {
    try {
      const resolution = await this.http
        .get(`${environment.api}/api/v1/resolution_os/${id}`)
        .toPromise();
      console.log('resolution Id', resolution);
      return resolution;
    } catch (error) {
      console.log('Erro ao receber a resolution', error);
    }
  }

  async getAllVisitsWhenOnline(farmId?: number, date?: string): Promise<any[]> {
    await this.sendCreatedVisitsWhenOnline();

    try {
      const params = `&start_date=${date}&end_date=${date}`;

      const visits = await this.http
        .get<any>(`${environment.api}/api/v1/diary?farm_id=${farmId}${params}`)
        .pipe(map((visitDiaries) => visitDiaries))
        .toPromise();

      const { rows } = visits;

      if (rows.length) {
        let cachedVisitDiaries = await this.cacheService.get('visit-diaries');

        rows.forEach((visitDiary: VisitDiaryModel) => {
          visitDiary.transmitted = true;
          if (!itemExists(visitDiary, cachedVisitDiaries)) {
            cachedVisitDiaries.push(visitDiary);
          } else {
            cachedVisitDiaries = cachedVisitDiaries.map((cachedVisitDiaries) =>
              cachedVisitDiaries.id === visitDiary.id
                ? visitDiary
                : cachedVisitDiaries
            );
          }
          this.cacheService.update('visit-diaries', cachedVisitDiaries);
          // console.log('cachedVisitDiaries', cachedVisitDiaries);
          // console.log('rows', rows);
        });

        rows.sort(sortByProperty('created_at'));
        return rows;
      }
    } catch (error) {
      console.error(
        'Erro ao carregar todas as ordens de serviço online',
        error
      );
    }
  }

  async getVisitsWhenOffLine(farmId?: number): Promise<VisitDiaryModel[]> {
    try {
      let visitDiaries = await this.cacheService.get('visit-diaries');
      visitDiaries = visitDiaries.filter(
        (visit: VisitDiaryModel) => visit.farm_id == farmId
      );

      visitDiaries.sort(sortByProperty('created_at'));

      console.log('visitas', visitDiaries);

      return visitDiaries;
    } catch (error) {
      console.error('Erro ao carregar ordens de serviço', error);
    }
  }

  async sendCreatedVisitsWhenOnline(): Promise<VisitDiaryModel[]> {
    let visitDiaries: VisitDiaryModel[] = [];

    try {
      let cachedVisitDiaries = await this.cacheService.get('visit-diaries');
      cachedVisitDiaries = cachedVisitDiaries.filter(
        (createdVisit) => !createdVisit.id
      );
      console.log('diaries sem ID', cachedVisitDiaries);

      if (cachedVisitDiaries && cachedVisitDiaries.length) {
        visitDiaries = await this.http
          .post<VisitDiaryModel[]>(
            `${environment.api}/api/v1/diary_array`,
            cachedVisitDiaries
          )
          .pipe(map((visitDiaries) => visitDiaries))
          .toPromise();
        console.log('visitDiariesResponse', visitDiaries);

        const response = await this.http
          .get<any>(`${environment.api}/api/v1/diary`)
          .toPromise();
        console.log('responseGet', response);
        visitDiaries = response.rows.map((visitDiary) => {
          visitDiary.transmitted = true;
          return visitDiary;
        });

        // console.log('updatedServiceOrders', serviceOrders);

        // await this.cacheService.update(`visit-diaries`, []);
      }
      return visitDiaries;
    } catch (error) {
      console.error('Erro ao enviar diarios de visita para o servidor', error);
    }
  }

  async getVisitByIdWhenOnline(id) {
    try {
      const visitById = await this.http
        .get(`${environment.api}/api/v1/diary/${id}`)
        .toPromise();
      console.log('visita pelo Id', visitById);
      return visitById;
    } catch (error) {
      console.log('Erro ao receber a visita do servidor', error);
    }
  }

  async getVisitByIdWhenOffline(id: string): Promise<VisitDiaryModel> {
    try {
      const foundVisitDiary = await this.cacheService.get('visit-diaries');
      const visitById = foundVisitDiary.find(
        (visit: VisitDiaryModel) => visit._id == id
      );
      return visitById;
    } catch (error) {
      console.error('Erro ao carregar o diário de visita por id', error);
    }
  }

  /**
   * SERVICE ORDERS
   *
   * Condition: Online
   */
  async getAllServiceOrdersWhenOnline(farmId?: number): Promise<any[]> {
    await this.sendCreatedServiceOrdersWhenOnline();

    try {
      const serviceOrders = await this.http
        .get<any>(`${environment.api}/api/v1/service_order?farm_id=${farmId}`)
        .pipe(map((serviceOrders) => serviceOrders))
        .toPromise();

      const { rows } = serviceOrders;

      if (rows.length) {
        let cachedServiceOrders = await this.cacheService.get('service-orders');

        rows.forEach((serviceOrder: ServiceOrderModel) => {
          serviceOrder.transmitted = true;
          if (!itemExists(serviceOrder, cachedServiceOrders)) {
            cachedServiceOrders.push(serviceOrder);
          } else {
            cachedServiceOrders = cachedServiceOrders.map(
              (cachedServiceOrders) =>
                cachedServiceOrders.id === serviceOrder.id
                  ? serviceOrder
                  : cachedServiceOrders
            );
          }
          this.cacheService.update('service-orders', cachedServiceOrders);
        });

        rows.sort(sortByProperty('created_at'));

        return rows;
      }
    } catch (error) {
      console.error(
        'Erro ao carregar todas as ordens de serviço online',
        error
      );
    }
  }

  /**
   * SERVICE ORDERS
   *
   * * Condition: Offline
   */
  async getServiceOrders(farmId?: number): Promise<ServiceOrderModel[]> {
    try {
      let serviceOrders = await this.cacheService.get('service-orders');
      serviceOrders = serviceOrders.filter(
        (so: ServiceOrderModel) => so.farm_id == farmId
      );

      serviceOrders = serviceOrders.map(
        (os: ServiceOrderModel, index: number) => {
          if (!os.SO_number) os.SO_number = generateTempSoNumber(index);
          return os;
        }
      );

      serviceOrders.sort(sortByProperty('created_at'));

      return serviceOrders;
    } catch (error) {
      console.error('Erro ao carregar ordens de serviço', error);
    }
  }

  /**
   * Condition: Offline
   */
  async getServiceOrderById(id: string): Promise<ServiceOrderModel> {
    try {
      let comments = await this.cacheService.get(
        CACHE_OBJECTS.SERVICE_ORDERS_COMMENTS
      );
      console.log('comments', comments);
      comments = comments.filter(
        (comment: CommentModel) => comment._serviceorder_id === id
      );
      console.log('filteredComments', comments);
      const foundServiceOrders = await this.cacheService.get('service-orders');
      console.log('foundServiceOrders', foundServiceOrders);
      const serviceOrderById = foundServiceOrders.find(
        (so: ServiceOrderModel) => so._id === id
      );
      console.log('serviceOrderById', serviceOrderById);
      if (!serviceOrderById.comments) serviceOrderById.comments = [];
      serviceOrderById.comments = serviceOrderById.comments.concat(comments);
      return serviceOrderById;
    } catch (error) {
      console.error('Erro ao carregar ordem de serviço por id', error);
    }
  }

  async getServiceOrderByIdWhenOnline(id) {
    const foundServiceOrder = await this.http
      .get<any>(`${environment.api}/api/v1/service_order/${id}`)
      .toPromise();
    return foundServiceOrder;
  }

  /**
   * Condition: Offline
   */
  async updateServiceOrder(
    serviceOrder: ServiceOrderModel
  ): Promise<ServiceOrderModel> {
    try {
      // atualiza online
      if (serviceOrder._id) {
        const updatedServiceOrder = await this.updateServiceOrderOnline(
          serviceOrder
        );
        if (updatedServiceOrder) {
          serviceOrder = updatedServiceOrder;
        }
      } else {
        await this.createServiceOrder(serviceOrder);
      }

      let foundServiceOrders = await this.cacheService.get('service-orders');

      const indexOf = foundServiceOrders.findIndex(
        (foundSO: ServiceOrderModel) => serviceOrder._id == foundSO._id
      );
      foundServiceOrders[indexOf] = serviceOrder;

      await this.cacheService.update('service-orders', foundServiceOrders);
      return foundServiceOrders[indexOf];
    } catch (error) {
      console.error('Erro ao atualizar ordem de serviço', error);
    }
  }

  async updateServiceOrderOnline(
    serviceOrder: ServiceOrderModel
  ): Promise<ServiceOrderModel> {
    try {
      const updatedServiceOrder = await this.http
        .put<ServiceOrderModel[]>(
          `${environment.api}/api/v1/service_order/${serviceOrder.id}`,
          serviceOrder
        )
        .pipe(map((serviceOrders) => serviceOrders))
        .toPromise();

      return updatedServiceOrder[0];
    } catch (error) {
      console.error('Erro ao atualizar ordem de serviço', error);
    }
  }

  /**
   * Condition: Offline
   */
  async createServiceOrder(body: any): Promise<ServiceOrderModel> {
    try {
      let foundServiceOrders = await this.cacheService.get('service-orders');
      // body.problems = body.problems.map(problem => problem = {
      //   problem: problem
      // })
      foundServiceOrders.push(body);

      return await this.cacheService.insert(
        'service-orders',
        foundServiceOrders
      );
    } catch (error) {
      console.error('Erro ao criar ordem de serviço', error);
    }
  }

  /**
   * Condition: Online
   */
  async createServiceOrderOnline(body: any): Promise<ServiceOrderModel> {
    try {
      const createdServiceOrder = await this.http
        .post<ServiceOrderModel[]>(
          `${environment.api}/api/v1/service_order`,
          body
        )
        .pipe(map((serviceOrders) => serviceOrders))
        .toPromise();

      return createdServiceOrder[0];
    } catch (error) {
      console.error('Erro ao criar ordem de serviço', error);
    }
  }

  async sendComment(comment: CommentModel): Promise<CommentModel> {
    return new Promise(async (resolve, reject) => {
      if (
        (await this.networkService.isConnected()) &&
        comment.serviceorder_id
      ) {
        try {
          const response = await this.http
            .post(
              `${environment.authApi}/api/v1/service_order/${comment.serviceorder_id}/comment`,
              comment
            )
            .toPromise();
          resolve(response as CommentModel);
        } catch (error) {
          console.log('Error on sendComment function', error);
          reject(error);
        }
      } else {
        let foundComments = await this.cacheService.get(
          CACHE_OBJECTS.SERVICE_ORDERS_COMMENTS
        );
        if (!foundComments) foundComments = [];
        foundComments.push(comment);
        await this.cacheService.insert(
          CACHE_OBJECTS.SERVICE_ORDERS_COMMENTS,
          foundComments
        );
        resolve(comment);
      }
    });
  }

  async sendCreatedServiceOrdersWhenOnline(): Promise<ServiceOrderModel[]> {
    let serviceOrders: ServiceOrderModel[] = [];

    try {
      let cachedServiceOrders = await this.cacheService.get('service-orders');
      cachedServiceOrders = cachedServiceOrders.filter(
        (createdOS) => !createdOS.id || createdOS.is_edited
      );

      if (cachedServiceOrders && cachedServiceOrders.length) {
        serviceOrders = await this.http
          .post<ServiceOrderModel[]>(
            `${environment.api}/api/v1/service_order_array`,
            cachedServiceOrders
          )
          .pipe(map((serviceOrders) => serviceOrders))
          .toPromise();

        let comments: CommentModel[] = await this.cacheService.get(
          CACHE_OBJECTS.SERVICE_ORDERS_COMMENTS
        );

        serviceOrders.forEach((serviceOrder) => {
          comments
            .filter((comment) => comment._serviceorder_id === serviceOrder._id)
            .forEach(async (comment) => {
              comment.serviceorder_id = serviceOrder.id;
              await this.sendComment(comment);
            });
        });
        console.log('aq---------ui -------------------');
        const response = await this.http.get<any>(
          `${environment.api}/api/v1/service_order`
        );
        response.subscribe((e) => {
          console.log('aq---------ui ------------------- 2');
          serviceOrders = e.rows.map((serviceOrder) => {
            serviceOrder.transmitted = true;
            serviceOrder.so_costs = serviceOrder.so_costs;
            return serviceOrder;
          });
        });

        // console.log('updatedServiceOrders', serviceOrders);

        await this.cacheService.update(
          CACHE_OBJECTS.SERVICE_ORDERS_COMMENTS,
          []
        );
        await this.cacheService.update(`service-orders`, serviceOrders);
      }

      return serviceOrders;
    } catch (error) {
      console.error('Erro ao enviar ordens de serviço para o servidor', error);
    }
  }

  /**
   * CHECKLISTS
   * Condition: Online
   */

  async sendCreatedChecklistsWhenOnline(farmId) {
    try {
      let results = [];
      let cachedChecklists = await this.cacheService.get('checklist-answers');
      cachedChecklists = cachedChecklists.filter(
        (createdChecklist) => !createdChecklist.id
      );
      console.log('cachedChecklists', cachedChecklists);

      for (let checklist of cachedChecklists) {
        console.log('for de checklistCaches', checklist);
        const sentChecklist = await this.http
          .post<ChecklistModel>(
            `${environment.api}/api/v1/checklist`,
            checklist
          )
          .pipe(map((result) => result))
          .toPromise();
        console.log('sentChecklist', sentChecklist);

        if (sentChecklist) {
          let checklistArray: any = await this.http
            .get(`${environment.api}/api/v1/checklist/?farm_id=${farmId}`)
            .pipe(map((result) => result))
            .toPromise();
          checklistArray = checklistArray.map((checklist) => {
            return { ...checklist, transmitted: true };
          });
          console.log('array de checklists', checklistArray);
          let cachedChecklistArray = await this.cacheService.insert(
            'checklist-answers',
            checklistArray
          );
          console.log('cachedChecklistArray', cachedChecklistArray);
        }

        // if (sentChecklist) {
        //   let checklistArray = await this.http.get(`${environment.api}/api/v1/checklist/?farm_id=${farmId}`).pipe(map(result => result)).toPromise();
        //   results.push(checklistArray);
        //   console.warn('enviando checklist...', checklist);
        // }
      }

      // if (results.length) {
      //   // Atualiza o cache
      //   await this.cacheService.update('checklist-answers', results);
      // }
      //
      // return results;
    } catch (error) {
      console.error('Erro ao enviar checklists para o servidor', error);
    }
  }

  async getChecklistTypeWhenOnline() {
    console.log('entrou');
    let checkListTypes = await this.http
      .get(`${environment.api}/api/v1/section`)
      .toPromise();
    let cachedListTypes = await this.cacheService.insert(
      'all-sections',
      checkListTypes
    );
    return cachedListTypes;
  }

  async getChecklistTypeWhenOffline() {
    let cachedChecklists = await this.cacheService.get('all-sections');
    return cachedChecklists;
  }

  /**
   *
   * Condition: Online
   */
  async getAllChecklistsWhenOnline(farmId): Promise<ChecklistModel[]> {
    await this.sendCreatedChecklistsWhenOnline(farmId);

    let serverChecklists: ChecklistModel[] = await this.http
      .get<ChecklistModel[]>(
        `${environment.api}/api/v1/checklist/?farm_id=${farmId}`
      )
      .pipe(map((result) => result))
      .toPromise();
    serverChecklists = serverChecklists.length ? serverChecklists : [];

    if (serverChecklists.length) {
      const checklists: ChecklistModel[] = await this.cacheService.get(
        `checklist-answers`
      );

      serverChecklists.forEach((serverChecklist) => {
        if (!itemExists(serverChecklist, checklists)) {
          checklists.push(serverChecklist);
          this.cacheService.update(`checklist-answers`, checklists);
        }
      });

      serverChecklists.sort(sortByProperty('created_at'));
    }

    return serverChecklists;
  }

  async syncVisitDiaries() {
    let visitDiaryArray = [];
    let userFarms = await this.cacheService.get('get-user-farms');
    for (const farm of userFarms) {
      try {
        let visitDiaries: any = await this.http
          .get(`${environment.api}/api/v1/diary?farm_id=${farm.id}`)
          .toPromise();
        console.log('visitDiariesPreRows', visitDiaries);
        visitDiaries = visitDiaries.rows;
        console.log('visitDiaries', visitDiaries);
        visitDiaries.map((visitDiary) => {
          visitDiary.transmitted = true;
        });
        if (visitDiaries?.length) {
          visitDiaryArray = visitDiaryArray.concat(visitDiaries);
        }
      } catch (e) {
        console.log('Erro ao sincronizar os dados de visitas', e);
      }
    }
    console.log('visitDiariesArray', visitDiaryArray);
    return await this.cacheService.insert('visit-diaries', visitDiaryArray);
  }

  async syncChecklists() {
    let checklistArray = [];
    let userFarms = await this.cacheService.get('get-user-farms');
    for (const farm of userFarms) {
      console.log('farm', farm);
      try {
        let checklists: any = await this.http
          .get(`${environment.api}/api/v1/checklist/?farm_id=${farm.id}`)
          .toPromise();
        console.log('checklists', checklists);
        checklists.map((checklist) => {
          checklist.transmitted = true;
        });
        if (checklists?.length) {
          checklistArray = checklistArray.concat(checklists);
        }
      } catch (e) {
        console.log('Erro ao sincronizar os dados de checklists', e);
      }
    }
    console.log('checklistsArray', checklistArray);
    return await this.cacheService.insert('checklist-answers', checklistArray);
  }

  async syncServiceOrders() {
    let serviceOrderArray = [];
    let userFarms = await this.cacheService.get('get-user-farms');
    for (const farm of userFarms) {
      try {
        let serviceOrders: any = await this.http
          .get(`${environment.api}/api/v1/service_order/?farm_id=${farm.id}`)
          .toPromise();
        serviceOrders = serviceOrders.rows;
        serviceOrders.map((serviceOrder) => {
          serviceOrder.transmitted = true;
        });
        if (serviceOrders?.length) {
          serviceOrderArray = serviceOrderArray.concat(serviceOrders);
        }
      } catch (e) {
        console.log('Erro ao sincronizar os dados de ordens de serviço', e);
      }
    }
    console.log('farmArray', serviceOrderArray);
    return await this.cacheService.insert('service-orders', serviceOrderArray);
  }

  async createCacheReasons() {
    if (!window.localStorage.getItem('reason-list')) {
      await this.cacheService.insert('reason-list', this.getAllReasons());
      window.localStorage.setItem('reason-list', 'true');
    }
  }

  async createCacheProblems() {
    if (!window.localStorage.getItem('problem-list')) {
      await this.cacheService.insert('problem-list', this.getAllProblems());
      window.localStorage.setItem('problem-list', 'true');
    }
  }

  async syncProblems() {
    await this.cacheService.insert('problem-list', this.getAllProblems());
  }

  async createCompatibilityCache() {
    this.cacheService.insert(
      'compatibility-table',
      this.getCompatibilityTable()
    );
  }

  /**
   *
   * Condition: Offline
   */
  async getChecklists(farmId: number): Promise<ChecklistModel[]> {
    try {
      let checklists = await this.cacheService.get('checklist-answers');
      checklists = checklists.filter(
        (checklist: ChecklistModel) => checklist.farm_id == farmId
      );

      if (checklists.length) {
        checklists = checklists.map(
          (checklist: ChecklistModel, index: number) => ({
            _id: checklist._id,
            checklist_number: checklist.checklist_number
              ? checklist.checklist_number
              : generateTempCheckListNumber(index),
            created_at: checklist.created_at
              ? checklist.created_at
              : new Date(),
            time_at: checklist.created_at ? checklist.created_at : new Date(),
            transmitted: checklist.transmitted,
          })
        );

        checklists.sort(sortByProperty('checklist_number'));
      }

      return checklists;
    } catch (error) {
      console.error('Erro ao carregar checklists', error);
    }
  }

  /**
   * ANSWERS
   */
  async saveChecklistAnswers(questions: any) {
    const checklistAnswers: any[] = await this.cacheService.get(
      'checklist-answers'
    );

    let questionOnStorage = checklistAnswers.find(
      (a) => a._id == questions._id
    );
    if (questionOnStorage) {
      questionOnStorage = questions;
      await this.cacheService.insert('checklist-answers', checklistAnswers);
      return checklistAnswers;
    }

    checklistAnswers.push(questions);
    await this.cacheService.insert('checklist-answers', checklistAnswers);
    console.log(
      'lista de checklists',
      this.cacheService.get('checklist-answers')
    );

    return checklistAnswers;
  }

  async saveChecklistAnswersWhenOnline(questions: any) {
    try {
      const sentChecklist = await this.http
        .post<ChecklistModel>(`${environment.api}/api/v1/checklist`, questions)
        .pipe(map((result) => result))
        .toPromise();
      let cachedChecklists = await this.cacheService.get('checklist-answers');
      cachedChecklists.push(sentChecklist);
      await this.cacheService.insert('checklist-answers', cachedChecklists);
      return sentChecklist;
    } catch (error) {
      console.error('Erro ao enviar checklist para o servidor', error);
    }
  }

  /**
   * Condition: Online
   */
  async getChecklistById(id: number): Promise<ChecklistModel> {
    try {
      const checkLists = await this.cacheService.get('checklist-answers');
      const checklistById = checkLists.find(
        (checklist: ChecklistModel) => checklist._id == id
      );
      return checklistById;
    } catch (error) {
      console.error('Erro ao carregar checklist', error);
    }
  }

  async verifyServiceOrdersTransmitted(): Promise<boolean> {
    const serviceOrders = await this.cacheService.get('service-orders');
    const transmitted = serviceOrders.every(
      (transm: ServiceOrderModel) => transm.transmitted == true
    );
    // console.log(transmitted);
    return transmitted;
  }

  async verifyChecklistsTransmitted(): Promise<boolean> {
    const checklists = await this.cacheService.get('checklist-answers');
    const transmitted = checklists.every(
      (transm: ChecklistModel) => transm.transmitted == true
    );
    return transmitted;
  }

  /**
   * QUESTIONS
   * Condition: Online
   */
  async getAllQuestionsOnline(): Promise<QuestionModel[]> {
    console.log('pegou as questions');
    try {
      const questions = await this.http
        .get<QuestionModel[]>(`${environment.api}/api/v1/question?active=true`)
        .pipe(map((question) => question))
        .toPromise();

      await this.cacheService.insert('all-questions', questions);
      const allQuestions = await this.cacheService.get('all-questions');

      return allQuestions;
    } catch (error) {
      console.error('Erro ao recuperar lista de perguntas do Checklist', error);
    }
  }

  /**
   *
   * Condition: Offline
   */
  async getAllQuestions(): Promise<QuestionModel[]> {
    try {
      const allQuestions = await this.cacheService.get('all-questions');
      return allQuestions;
    } catch (error) {
      console.error('Erro ao recuperar lista de perguntas do Checklist', error);
    }
  }

  /**
   * SECTIONS
   * Condition: Online
   */
  async getAllSectionsOnline(): Promise<SectionModel[]> {
    console.log('pegou as sections');
    try {
      const sections = this.http
        .get<SectionModel[]>(`${environment.api}/api/v1/section/`)
        .pipe(map((section) => section))
        .toPromise();

      let allSections = await this.cacheService.insert(
        'all-sections',
        sections
      );
      return allSections;
    } catch (error) {
      console.error('Erro ao carregar as sections', error);
    }
  }

  /**
   * SECTIONS
   * Condition: Offline
   */
  async getAllSectionsWhenOnline() {
    try {
      let allSections: any = await this.http
        .get(`${environment.api}/api/v1/section`)
        .toPromise();
      allSections = allSections.filter(
        (sectionsActive) => sectionsActive.active
      );
      await this.cacheService.insert('all-sections', allSections);
      const allSectionsFiltered = await this.cacheService.get('all-sections');
      console.log('filteredSections', allSectionsFiltered);
      return allSectionsFiltered;
    } catch (error) {
      console.error('Erro ao carregar as sections', error);
    }
  }

  async getAllSectionsWhenOffline() {
    let allSections: any = await this.cacheService.get('all-sections');
    let allSectionsFiltered = allSections.filter(
      (sectionActive) => sectionActive.active
    );
    return allSectionsFiltered;
  }

  /**
   * PRODUCT REPORTS (Laudos)
   * Condition: ainda não sei
   */
  getProductReports(params: string) {
    const productReports = this.http.get<any>(
      `${environment.api}/api/v1/product_report${params}`
    );
    return productReports;
  }

  /**
   * Condition: ainda não sei
   */
  getProductReportById(id: number): Promise<ProductReportModel> {
    const productReportById = this.http
      .get<ProductReportModel>(`${environment.api}/api/v1/product_report/${id}`)
      .toPromise();

    return productReportById;
  }

  downloadPDF(url: string): any {
    return this.http.get(url, { responseType: 'blob' }).toPromise();
  }

  async getCompatibilityTable() {
    return await this.http
      .get(`${environment.api}/api/v1/compatibility/results`)
      .pipe(map((elem) => elem))
      .toPromise();
  }

  async getCulturesList() {
    let cultureList = await this.http
      .get(`${environment.api}/api/v1/culture`)
      .pipe(map((elem) => elem))
      .toPromise();
    await this.cacheService.insert('cultureList', cultureList);
    return cultureList;
  }

  async createManagementPlan(managementPlan) {
    let plan = await this.http
      .post(`${environment.api}/api/v1/plan`, managementPlan)
      .pipe(map((elem) => elem))
      .toPromise();
    console.log('M-Plan', plan);
    await this.cacheService.insert('last-management-plan', plan);
    return plan;
  }

  async getPlanById(planId) {
    let plan = await this.http
      .get<PlanModel>(`${environment.api}/api/v1/plan/${planId}`)
      .toPromise();
    console.log('dataServicePlanById', plan);
    return plan;
  }

  async syncAllPlans() {
    let allPlans: any = await this.http
      .get(`${environment.api}/api/v1/plan`)
      .toPromise();
    await this.cacheService.insert('management-plans', allPlans.plans);
  }

  async getProductList() {
    let productList: any = await this.http
      .get(`${environment.api}/api/v1/product`)
      .toPromise();
    await this.cacheService.insert('product-list', productList.products);
    console.log('productList', productList);
    return productList;
  }

  async createStepPlan(planStepInfo) {
    let planStep = await this.http
      .post(`${environment.api}/api/v1/plan_step`, planStepInfo)
      .toPromise();
    console.log('planStep', planStep);
  }

  async updatePlanStep(planStepInfo) {
    let updatedPlanStep = await this.http
      .put(
        `${environment.api}/api/v1/plan_step/${planStepInfo.productId}`,
        planStepInfo
      )
      .toPromise();
    console.log('updatedPlanStep', updatedPlanStep);
  }

  async deletePlanStep(productId) {
    let deletedPlanStep = await this.http
      .delete(`${environment.api}/api/v1/plan_step/${productId}`, productId)
      .toPromise();
    console.log('deletedPlanStep', deletedPlanStep);
  }

  async getAllPlansByFarm(farmId) {
    let allPlans: any = await this.http
      .get(`${environment.api}/api/v1/plan`)
      .toPromise();
    await this.cacheService.insert('management-plans', allPlans.plans);
    allPlans = await this.cacheService.get('management-plans');
    return allPlans.filter((farmPlan) => farmPlan.farm_id == farmId);
  }

  async deletePlanById(planId) {
    return await this.http
      .delete(`${environment.api}/api/v1/plan/${planId}`)
      .toPromise();
  }

  async getPlanByOfflineId(planId) {
    let allPlans: any = await this.cacheService.get('management-plans');
    console.log('allPlansWhenOffline', allPlans);
    let filteredPlan: any = allPlans.find((plan) => plan.id == planId);
    console.log('filteredPlan', filteredPlan);
    return filteredPlan;
  }

  async getAllPlansByFarmOffline(farmId) {
    let allPlans: any = await this.cacheService.get('management-plans');
    let filteredPlans: any = allPlans.filter((plan) => plan.farm_id == farmId);
    console.log('plansByFarmOffline', filteredPlans);
    return filteredPlans;
  }

  async deleteUser(userId) {
    return await this.http
      .delete(`${environment.api}/api/v1/user/${userId}`)
      .toPromise();
  }

  async getLaboratoryById(id: number): Promise<LaboratoryModel> {
    const laboratory = this.http
      .get<LaboratoryModel>(`${environment.api}/api/v1/laboratory/${id}`)
      .toPromise();

    return laboratory;
  }
}
