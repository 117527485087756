import { Injectable } from '@angular/core';
import {ToastController} from "@ionic/angular";


@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private toastController: ToastController,
  ) { }

  async presentDefaultToast(message, color, position, duration?) {
    const toast = await this.toastController.create({
      message: message,
      color: color,
      position: position,
      duration: duration || 2000,

    });
    toast.present();
  }

}
