import { Component, Input, OnInit, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { FarmModel } from '../../domains/models/farm.model';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
  @Input() farms: FarmModel[] = [];
  farm: FarmModel = new FarmModel();
  selectedFarmId: number;
  selectedFarmName: string;
  selectedFarmIdentity: string;
  timestamp: string;
  filtro: string;

  constructor(
    public popoverController: PopoverController) { }

  ngOnInit() {

  }

  async selectFarm(farm: FarmModel) {
    this.selectedFarmId = farm.id;
    this.selectedFarmName = farm.farm_name;
    this.selectedFarmIdentity = farm.identity_name;
    this.popoverController.dismiss({ selectedFarmId: this.selectedFarmId, selectedFarmName: this.selectedFarmName, selectedFarmIdentity: this.selectedFarmIdentity });
  }

  parseIdentityName(identityName, farmName){
    if(identityName == null){
      return farmName.toUpperCase();
    } else {
      return identityName.toUpperCase()
    }

  }

}
