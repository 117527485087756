import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-filter-timeline-modal',
  templateUrl: './filter-timeline-modal.component.html',
  styleUrls: ['./filter-timeline-modal.component.scss'],
})
export class FilterTimelineModalComponent implements OnInit {
  @Input() titleModal: string;
  @Input() filters: any[];


  filter: string;


  constructor(
    private modalCtrl: ModalController,

  ) {
  }

  async ngOnInit() {

  }

  addFilter(filter) {
    this.modalCtrl.dismiss({
      filter,
    });
  }

  modalDismiss() {
    this.modalCtrl.dismiss();
  }
}
